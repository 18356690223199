// ------------------------------
//
//     キャラクター画像に関する記述
//
// ------------------------------

// キャラクター画像の出力数に応じて「？」を表示する枚数を調整する実装
$(function () {
  const character_images = $(".choice li").length;
  const mystery_images = $('<li class="mistery"></li>');

  // 各キャラ開放ポイントを格納
  const release_point = [0, 200, 500, 800, 1200, 1800, 2300, 2800, 3300, 3800, 4300, 4800, 5300, 5800, 6300, 6800, 7300, 7800, 8300, 8800, 9300, 9800, 10300, 10800, 11300, 12000, 13000, 15000, 17000, 20000]

  // ■■■■■■　キャラクター画像がない場合、「残り●●●ptで開放」というリストを生成し表示させる　■■■■■■
  function MisteryImageDisplayNumber(n) {
    $(mystery_images).appendTo('.choice');
    for (let i = 0; i < n; i++) {
      $(mystery_images).clone().appendTo('.choice');
    }
  };

  // ■■■■■■　キャラクター画像の枚数 + 「残り●●●ptで開放」の枚数 = ３０枚を固定で表示させる処理　■■■■■■
  for (let i = 0; i < 29; i++) {
    if (character_images == i + 1) {
      MisteryImageDisplayNumber(28 - i);
    }
  };

  // ■■■■■■　非表示のキャラクターが後何ptで解放されるかを表示させる処理　■■■■■■ //
  const character = $('.choice li'); //キャラクターリスト30個全取得
  const now_point = $('.current_grade_point').html(); //現在の段位ポイントを「昇段ポイント」から取得

  // 全取得したリストの中で「mistery」クラスを持っている場合のみ、表示させるまでの残りポイントを表示させる。
  for (let i = 0; i < 30; i++) {
    if (character.eq(i).hasClass('mistery')) {
      character.eq(i).text("残り" + `${release_point[i] - now_point}` + "ptで開放");
    }
  }
});
